@charset "UTF-8";
/**
 *
 *  TOP
 *
 */
/* --------------------------------------------------------------------------
	 loader
--------------------------------------------------------------------------- */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #fff;
  backface-visibility: hidden;
  backface-visibility: hidden;
}

.loader__title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 207px;
  height: 75px;
  background: url(/assets/img/mv_logo_loading.png) no-repeat 50%;
  background-size: contain;
}

.mv_slide_logo img {
  width: 408px;
}

@media only screen and (max-width: 767px) {
  .mv_slide_logo img {
    width: 260px;
    height: 60px;
  }
}

.loader__bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
}

.loader__bar div {
  width: 0;
  height: 100%;
  background: #ed6b00;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .loader__title {
    width: 186px;
    height: 17px;
  }
  .loader__bar {
    height: 3px;
  }
}

/* --------------------------------------------------------------------------
	 mv
--------------------------------------------------------------------------- */
.mv {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.mv__outer {
  position: relative;
  z-index: 1;
  display: table;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  backface-visibility: hidden;
}

.mv__inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* mv__images
----------------------------------------------------------------- */
.mv__images,
.mv__images .item,
.mv__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mv__images .item {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  backface-visibility: hidden;
}

.mv__images .item.is-active {
  z-index: 1;
}

.mv__images .item--01 {
  background-image: url(../img/mv_bg01.jpg);
}

.mv__images .item--02 {
  background-image: url(../img/mv_bg02.jpg);
}

.mv__images .item--03 {
  background-image: url(../img/mv_bg03.jpg);
}

.mv__images .item--04 {
  background-image: url(../img/mv_bg04.jpg);
}

.mv__layer {
  background: url(../img/mv_bg_layer.png);
}

/* mv__headline
----------------------------------------------------------------- */
.mv__headline {
  padding-bottom: 30px;
}

.mv__headline__logo {
  overflow: hidden;
  margin-bottom: 30px;
}

.mv__headline__hdg {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding-bottom: 36px;
}

.mv__headline__hdg .bar {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 6px;
  background: #fb8c0d;
}

.mv__headline__hdg .bar--left {
  right: 50%;
}

.mv__headline__hdg .bar--right {
  left: 50%;
}

/* mv__scroll
----------------------------------------------------------------- */
.mv__scroll {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 85px;
}

.mv__scroll a {
  position: relative;
  display: block;
  padding-bottom: 90px;
  color: #fff;
  text-align: center;
  letter-spacing: -1.5px;
  font-weight: 300;
  font-size: 18px;
}

.mv__scroll a::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 1px;
  height: 80px;
  background: #fff;
  content: '';
}

.mv__scroll span {
  display: inline-block;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  /* mv__images
	----------------------------------------------------------------- */
  .mv__images .item--01 {
    background-image: url(../img/mv_bg01_sp.jpg);
  }
  .mv__images .item--02 {
    background-image: url(../img/mv_bg02_sp.jpg);
  }
  .mv__images .item--03 {
    background-image: url(../img/mv_bg03_sp.jpg);
  }
  .mv__images .item--04 {
    background-image: url(../img/mv_bg04_sp.jpg);
  }
  .mv__layer {
    background-size: 10px 10px;
  }
  /* mv__headline
	----------------------------------------------------------------- */
  .mv__headline {
    padding-bottom: 15px;
  }
  .mv__headline__logo {
    margin-bottom: 15px;
  }
  .mv__headline__logo img {
    width: 100px;
    height: auto;
  }
  .mv__headline__hdg {
    padding-bottom: 12px;
  }
  .mv__headline__hdg .hdg img {
    width: 268px;
    height: auto;
  }
  .mv__headline__hdg .bar {
    height: 3px;
  }
  /* mv__scroll
	----------------------------------------------------------------- */
  .mv__scroll {
    width: 50px;
  }
  .mv__scroll a {
    padding-bottom: 45px;
    letter-spacing: -1px;
    font-size: 10px;
  }
  .mv__scroll a::before {
    height: 35px;
  }
}

/* --------------------------------------------------------------------------
	 topnews  2018/03/14 updated
--------------------------------------------------------------------------- */
.topnews {
  position: relative;
  z-index: 2;
  display: table;
  margin: -104px auto 0;
  width: 100%;
  box-shadow: 0 5px 15px -4px #eee;
  table-layout: fixed;
}

.topnews .m-hdg {
  display: table-cell;
  padding: 24px 0 22px;
  width: 250px;
  background: #212121;
  vertical-align: top;
}

.topnews .m-hdg .m-hdg__ttl-ja {
  color: #fff;
  font-size: 28px;
}

.topnews .m-hdg .m-hdg__ttl-en {
  margin-top: 1px;
  font-size: 12px;
}

.topnews .m-hdg .topnews__btn {
  margin: 33px auto 0;
  width: 180px;
}

.topnews .m-hdg .topnews__btn a {
  padding: 11px 0;
}

.topnews .topnews__list {
  display: table-cell;
  padding: 1px 35px;
  background: #fff;
  vertical-align: top;
}

.topnews .topnews__list .item {
  padding: 17px 0;
}

.topnews .topnews__list .item:first-child {
  border-top: none;
}

.topnews .topnews__list .item:last-child {
  border-bottom: none;
}

/* media query -> pc
================================================================= */
@media only screen and (min-width: 768px) {
  .topnews {
    max-width: 1400px;
  }
  .swiper-slide .s_cont {
    margin-top: 190px;
  }
  .swiper-slide.s12 .s_cont {
    margin-top: 400px;
  }
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .topnews {
    display: block;
    margin: -62px auto 0;
    width: 87.5%;
  }
  .topnews .m-hdg {
    display: block;
    padding: 16px 0 20px;
    width: 100%;
  }
  .topnews .m-hdg .m-hdg__ttl-ja {
    font-size: 17px;
  }
  .topnews .m-hdg .m-hdg__ttl-en {
    font-size: 10px;
  }
  .topnews .m-hdg .topnews__btn {
    margin: 11px auto 0;
    width: 190px;
  }
  .topnews .m-hdg .topnews__btn a {
    padding: 11px 0;
  }
  .topnews .topnews__list {
    display: block;
    padding: 0;
  }
  .topnews .topnews__list .item {
    padding: 0;
  }
}

/* --------------------------------------------------------------------------
	 about
--------------------------------------------------------------------------- */
.about {
  padding: 92px 0 81px;
  border-bottom: 1px solid #eaeaea;
  background: #fff;
}

.about__hdg {
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
}

.about__txt {
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 20px;
  line-height: 2;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .about {
    padding: 35px 0 45px;
  }
  .about__hdg {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .about__txt {
    letter-spacing: 0;
    font-size: 12px;
    line-height: 1.8;
  }
}

/* --------------------------------------------------------------------------
	 strength
--------------------------------------------------------------------------- */
.strength {
  position: relative;
  margin-top: 141px;
  height: 770px;
  background: url(../img/strength_bg.jpg) no-repeat 50%;
  background-size: cover;
}

.strength__item {
  width: 50%;
}

/* strength__head
----------------------------------------------------------------- */
.strength__head {
  float: right;
}

.strength__head__inner {
  padding-top: 100px;
}

.strength__head .headline {
  margin-bottom: 55px;
}

.strength__head .headline__hdg {
  margin-bottom: 12px;
  padding-left: 98px;
  font-weight: 300;
  font-size: 24px;
}

.strength__head .headline__lead {
  position: relative;
  padding-left: 98px;
  line-height: 1;
}

.strength__head .headline__lead::before,
.strength__head .headline__lead::after {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1px;
  background: #fff;
  content: '';
  opacity: 0.22;
}

.strength__head .headline__lead::before {
  left: 0;
  width: 72px;
}

.strength__head .headline__lead::after {
  right: 0;
  width: calc(100% - 552px);
}

.strength__head .details {
  display: table;
  width: 878px;
  height: 393px;
  background: #ec8510;
}

.strength__head .details__inner {
  display: table-cell;
  padding-left: 98px;
  vertical-align: middle;
}

.strength__head .details__hdg {
  color: #fff;
  letter-spacing: 2px;
  font-size: 28px;
  line-height: 2.3;
  opacity: 0.9;
}

.strength__head .details__txt {
  margin: 4px 0 0;
  color: #fff;
  letter-spacing: 0.8px;
  line-height: 2;
  opacity: 0.9;
}

.strength__head .details__btn {
  margin-top: 30px;
  width: 300px;
}

/* strength__body
----------------------------------------------------------------- */
.strength__body {
  position: absolute;
  top: -40px;
  left: 0;
  overflow: hidden;
  height: calc(100% + 80px);
}

/* strength__slider
----------------------------------------------------------------- */
.strength__slider {
  position: relative;
  float: right;
  width: 892px;
  height: 100%;
}

.strength__slider .slider {
  position: absolute;
  width: 50%;
  height: 100%;
  line-height: 0;
}

.strength__slider .slider--left,
.strength__slider .slider--left li {
  top: 0;
  left: 0;
}

.strength__slider .slider--right,
.strength__slider .slider--right li {
  right: 0;
  bottom: 0;
}

.strength__slider .slider__list {
  position: relative;
  height: 100%;
}

.strength__slider .slider__list li {
  position: absolute;
}

.strength__slider .slider img {
  width: 100%;
  height: auto;
}

/* pointer */
.strength__slider .slider__pointer {
  position: absolute;
  top: 0;
  left: -34px;
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  height: 100%;
  box-direction: normal;
  box-orient: vertical;
  box-pack: center;
  flex-pack: center;
}

.strength__slider .slider__pointer li {
  overflow: hidden;
  width: 1px;
  height: 62px;
  background: #686a68;
}

.strength__slider .slider__pointer li + li {
  margin-top: 3px;
}

.strength__slider .slider__pointer li span {
  display: block;
  height: 100%;
  background: #fa8e19;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .strength {
    margin-top: 0;
    height: auto;
    background-position: 50% 0;
    background-size: auto 90%;
  }
  .strength__item {
    width: auto;
  }
  /* strength__head
	----------------------------------------------------------------- */
  .strength__head {
    float: none;
  }
  .strength__head__inner {
    padding-top: 45px;
  }
  .strength__head .headline {
    margin-bottom: 40px;
  }
  .strength__head .headline__hdg {
    margin-bottom: 8px;
    padding-left: 0;
    text-align: center;
    font-size: 12px;
  }
  .strength__head .headline__lead {
    padding-left: 0;
  }
  .strength__head .headline__lead img {
    margin: auto;
  }
  .strength__head .headline__lead::before,
  .strength__head .headline__lead::after {
    width: 12%;
  }
  .strength__head .details {
    display: block;
    padding: 0 20px;
    width: auto;
    height: auto;
    background: inherit;
  }
  .strength__head .details__inner {
    display: block;
    padding: 20px 20px 25px;
    background: #ec8510;
  }
  .strength__head .details__hdg {
    letter-spacing: 1px;
    font-size: 16px;
  }
  .strength__head .details__txt {
    letter-spacing: 0;
    line-height: 1.8;
  }
  .strength__head .details__btn {
    margin-top: 20px;
    width: auto;
  }
  /* strength__body
	----------------------------------------------------------------- */
  .strength__body {
    position: relative;
    top: 0;
    padding: 0 5px;
    height: 90vw;
  }
  /* strength__slider
	----------------------------------------------------------------- */
  .strength__slider {
    float: none;
    width: 100%;
  }
  .strength__slider .slider {
    position: absolute;
    width: 50%;
    height: 100%;
    line-height: 0;
  }
  .strength__slider .slider--left,
  .strength__slider .slider--left li {
    top: 0;
    left: 0;
  }
  .strength__slider .slider--right,
  .strength__slider .slider--right li {
    right: 0;
    bottom: 0;
  }
  .strength__slider .slider__list {
    position: relative;
    height: 100%;
  }
  .strength__slider .slider__list li {
    position: absolute;
  }
  .strength__slider .slider img {
    width: 100%;
    height: auto;
  }
}

/* --------------------------------------------------------------------------
	 service
--------------------------------------------------------------------------- */
.service {
  overflow: hidden;
}

/* service__head
----------------------------------------------------------------- */
.service__head {
  padding: 102px 0 110px;
}

/* service__body
----------------------------------------------------------------- */
.service__body {
  position: relative;
  height: 510px;
}

.service__body .item {
  position: absolute;
  top: 0;
  min-width: 900px;
  width: 50%;
  height: 100%;
}

.service__body .item--01 {
  right: 50%;
}

.service__body .item--02 {
  left: 50%;
}

.service__body .item::before {
  position: absolute;
  top: -58px;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  width: 116px;
  height: 116px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  content: '';
}

.service__body .item--01::before {
  background-image: url(../img/service_ico_item01.png);
}

.service__body .item--02::before {
  background-image: url(../img/service_ico_item02.png);
}

.service__body .item a {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.service__body .item a::before,
.service__body .item a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.service__body .item a::before {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: -webkit-transform 3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.service__body .item--01 a::before {
  background-image: url(../img/service_img_item01.jpg);
}

.service__body .item--02 a::before {
  background-image: url(../img/service_img_item02.jpg);
}

.mode-pc .service__body .item a:hover::before {
  transform: scale(1.05);
  transform: scale(1.05);
}

.service__body .item a::after {
  background: #000;
  opacity: 0.2;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mode-pc .service__body .item a:hover::after {
  opacity: 0.7;
}

/* body */
.service__body .item__body {
  position: relative;
  z-index: 1;
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  box-align: center;
  box-pack: center;
  flex-align: center;
  flex-pack: center;
}

.service__body .item__body__ttl {
  transition: -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mode-pc .service__body .item a:hover .item__body__ttl {
  transform: translateY(-95px);
  transform: translateY(-95px);
}

.service__body .item__body__ttl span {
  display: block;
}

.service__body .item__body__ttl .ttl-ja {
  color: #fff;
  letter-spacing: 2px;
  font-size: 28px;
}

.service__body .item__body__ttl .ttl-en {
  margin-top: 3px;
  color: #fa8e19;
  letter-spacing: 1px;
  font-size: 12px;
}

.service__body .item__body__txt {
  position: absolute;
  right: 0;
  bottom: 145px;
  left: 0;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 2;
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(50px);
  transform: translateY(50px);
}

.service__body .item--01 .item__body__txt {
  bottom: 120px;
}

.service__body .item--02 .item__body__txt {
  bottom: 145px;
}

.mode-pc .service__body .item a:hover .item__body__txt {
  opacity: 1;
  transform: translateY(0);
  transform: translateY(0);
}

/* foot */
.service__body .item__foot {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  justify-content: center;
  width: 100%;
  height: 85px;
  transition: -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(100%);
  transform: translateY(100%);
  box-align: center;
  box-pack: center;
  flex-align: center;
  flex-pack: center;
}

.mode-pc .service__body .item a:hover .item__foot {
  transform: translateY(0);
  transform: translateY(0);
}

.service__body .item__foot::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  opacity: 0.4;
}

.service__body .item__foot__txt {
  position: relative;
  z-index: 1;
  padding-left: 18px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.4s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(-30px);
  transform: translateX(-30px);
}

.mode-pc .service__body .item a:hover .item__foot__txt {
  opacity: 1;
  transform: translateX(0);
  transform: translateX(0);
}

.service__body .item__foot__txt::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 5px 0 5px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #fb8c0d;
  content: '';
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 120px !important;
}

/* IE10~ */
@media all and (-ms-high-contrast: none) {
  .service__body .item__foot__txt::before {
    top: 0.25em;
    bottom: auto;
  }
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  /* service__head
	----------------------------------------------------------------- */
  .service__head {
    padding: 36px 0 25px;
  }
  /* service__body
	----------------------------------------------------------------- */
  .service__body {
    height: auto;
  }
  .service__body .item {
    position: relative;
    min-width: 0;
    width: auto;
  }
  .service__body .item--01 {
    right: 0;
  }
  .service__body .item--02 {
    left: 0;
  }
  .service__body .item::before {
    top: 40px;
    width: 58px;
    height: 58px;
  }
  .service__body .item a {
    display: block;
    padding: 0;
  }
  .service__body .item a::after {
    opacity: 0.5;
  }
  /* body */
  .service__body .item__body {
    display: block;
    padding: 115px 20px 0;
  }
  .service__body .item__body__ttl .ttl-ja {
    letter-spacing: 1px;
    font-size: 16px;
  }
  .service__body .item__body__ttl .ttl-en {
    letter-spacing: 0;
    font-size: 10px;
  }
  .service__body .item__body__txt {
    position: static;
    margin-top: 20px;
    letter-spacing: 0;
    line-height: 1.8;
    opacity: 1;
    transform: translateY(0);
    transform: translateY(0);
  }
  /* foot */
  .service__body .item__foot {
    position: relative;
    margin-top: 20px;
    height: 45px;
    transform: translateY(0);
    transform: translateY(0);
  }
  .service__body .item__foot__txt {
    padding-left: 9px;
    font-size: 12px;
    opacity: 1;
    transform: translateX(0);
    transform: translateX(0);
  }
  .service__body .item__foot__txt::before {
    border-width: 3px 0 3px 5px;
  }
}

/* --------------------------------------------------------------------------
	 case
--------------------------------------------------------------------------- */
.case {
  overflow: hidden;
}

/* case__head
----------------------------------------------------------------- */
.case__head {
  padding: 171px 0 55px;
}

/* case__body
----------------------------------------------------------------- */
.case__body {
  padding-bottom: 100px;
}

.case__body .soon {
  color: #fb8c0d;
  text-align: center;
  font-weight: bold;
  font-size: 46px;
}

.case__body__list {
  margin-bottom: 70px;
}

.case__body__list .list {
  overflow: hidden;
  margin: -1%;
}

.case__body__list .item {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 1%;
  width: 23%;
  line-height: 0;
}

.case__body__list .item a {
  position: relative;
  display: block;
}

.case__body__list .item__img img {
  width: 100%;
  height: auto;
}

.case__body__list .item__ttl {
  position: absolute;
  top: 0;
  left: 0;
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  box-align: center;
  box-pack: center;
  flex-align: center;
  flex-pack: center;
}

.case__body__list .item__ttl::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mode-pc .case__body__list .item:hover .item__ttl::before {
  opacity: 0.5;
}

.case__body__list .item__ttl .ttl {
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(20px);
  transform: translateY(20px);
}

.case__body__list .item a {
  display: block;
}

.case__body__list .item a .item__img {
  position: relative;
  display: block;
  padding-top: 50%;
  width: 100%;
}

.case__body__list .item__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

.mode-pc .case__body__list .item:hover .item__ttl .ttl {
  opacity: 1;
  transform: translateY(0);
  transform: translateY(0);
}

.case__body__btn {
  margin: auto;
  width: 300px;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  /* case__head
	----------------------------------------------------------------- */
  .case__head {
    padding: 37px 0 20px;
  }
  /* case__body
	----------------------------------------------------------------- */
  .case__body {
    padding-bottom: 40px;
  }
  .case__body .soon {
    font-size: 23px;
  }
  .case__body__list {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .case__body__list .list {
    margin: 0;
  }
  .case__body__list .item {
    float: none;
    margin: 0;
    width: auto;
  }
  .case__body__list .item + .item {
    margin-top: 20px;
  }
  .case__body__list .item__ttl {
    position: static;
    display: block;
    margin-top: 8px;
    width: auto;
    height: auto;
  }
  .case__body__list .item__ttl .ttl {
    color: #000;
    font-size: 12px;
    line-height: 1.5;
    opacity: 1;
  }
  .case__body__btn {
    width: 195px;
  }
  .case__body__list .item__img {
    overflow: hidden;
  }
}

/* --------------------------------------------------------------------------
	 news
--------------------------------------------------------------------------- */
/* news__head
----------------------------------------------------------------- */
.news__head {
  padding: 95px 0 45px;
}

/* news__body
----------------------------------------------------------------- */
.news__body__list {
  margin-bottom: 60px;
}

.news__body__btn {
  margin: auto;
  width: 300px;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  /* news__head
	----------------------------------------------------------------- */
  .news__head {
    padding: 35px 0 22px;
  }
  /* news__body
	----------------------------------------------------------------- */
  .news__body__list {
    margin-bottom: 30px;
  }
  .news__body__btn {
    width: 195px;
  }
}

/* --------------------------------------------------------------------------
	 banner
--------------------------------------------------------------------------- */
.banner {
  padding: 100px 0 110px;
}

.banner__list {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  justify-content: center;
  justify-content: center;
  margin: 0 -1%;
  box-pack: center;
  flex-pack: center;
}

.banner__list .item {
  margin: 0 1%;
  width: 24%;
  line-height: 0;
}

.banner__list .item a {
  position: relative;
  display: block;
}

.banner__list .item a::before,
.banner__list .item a::after {
  position: absolute;
  width: 0;
  height: 0;
  content: '';
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.banner__list .item a::before {
  top: 0;
  right: 0;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
}

.banner__list .item a::after {
  bottom: 0;
  left: 0;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
}

.mode-pc .banner__list .item a:hover::before,
.mode-pc .banner__list .item a:hover::after {
  width: 100%;
  height: 100%;
}

.mode-pc .banner__list .item a:hover::before {
  border-top-color: #fb8c0d;
  border-right-color: #fb8c0d;
}

.mode-pc .banner__list .item a:hover::after {
  border-bottom-color: #fb8c0d;
  border-left-color: #fb8c0d;
}

.banner__list .item .note {
  margin-top: 1em;
  font-size: 12px;
  line-height: 1.5;
}

.banner__list .item img {
  width: 100%;
  height: auto;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .banner {
    padding: 40px 0;
  }
  .banner__list {
    display: block;
  }
  .banner__list .item {
    width: auto;
  }
  .banner__list .item + .item {
    margin-top: 10px;
  }
  .banner__list .item .note {
    font-size: 10px;
  }
}

.sw {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}

.swiper-slide.s1 {
  z-index: -6;
  background: #9e9e9e;
  opacity: 1 !important;
  transition: all 1s;
  transition: all 1s;
}

.swiper-slide.s1.swiper-slide-active,
.swiper-slide.s1.swiper-slide-duplicate-active {
  background: none;
  opacity: 0 !important;
  transition: all 1s;
  transition: all 1s;
}

.swiper-slide.s2 {
  background-image: url(../img/s2.jpg);
  background-position: center;
  background-size: cover;
}

.swiper-slide.s3 {
  background-image: url(../img/s3.jpg);
  background-size: cover;
}

.swiper-slide.s4 {
  background-image: url(../img/s4.jpg);
  background-position: center;
  background-size: cover;
}

.swiper-slide.s5 {
  background-image: url(../img/s5.jpg);
  background-size: cover;
}

.swiper-slide.s6 {
  background-image: url(../img/s6.jpg);
  background-size: cover;
}

.swiper-slide.s7 {
  background-image: url(../img/s7.jpg);
  background-size: cover;
}

.swiper-slide.s8 {
  background-image: url(../img/s8.jpg);
  background-size: cover;
}

.swiper-slide.s9 {
  background-image: url(../img/s9.jpg);
  background-position: center;
  background-size: cover;
}

.swiper-slide.s10 {
  background-image: url(../img/s10.jpg);
  background-position: center;
  background-size: cover;
}

.swiper-slide.s11 {
  background: #fedd1e;
}

.swiper-slide.s12 {
  background-image: url(../img/s12.jpg);
  background-position: center;
  background-size: cover;
}

.swiper-slide .s_cont {
  text-align: center;
}

.swiper-slide .s_cont .s-ttl {
  position: relative;
  display: inline-block;
  color: #fff;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 48px;
}

.swiper-slide .s_cont .s-ttl::before {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 22px;
  background: #ec6a00;
  content: '';
}

.swiper-slide .s_cont .s-subttl {
  margin-top: 20px;
}

.swiper-slide .s_cont .s-typo {
  margin-top: 35px;
  padding: 0 90px;
}

.swiper-slide .s_cont .s-typo img {
  max-width: 100%;
  height: auto;
}

.swiper-slide .s_cont .s-txt {
  margin-top: 44px;
  color: #fff;
  font-size: 23px;
}

.swiper-slide .s_cont .s-txt span {
  padding: 6px 0;
  background: #fff;
  color: #ec6a00;
}

.swiper-slide .s_cont .s-txt .line {
  padding: 0 0 2px;
  border-bottom: 4px solid #ec6a00;
  background: inherit;
  color: inherit;
}

.swiper-slide .s_cont .s-btn {
  margin: 70px auto 0;
  width: 270px;
  height: 70px;
  border: 2px solid #fff;
  transition: 0.3s;
}

.s-txt-inner .s-txt a:hover {
  opacity: 0.8;
}

.swiper-slide .s_cont .s-btn:hover {
  border: 2px solid #333;
  background: #333;
  transition: 0.3s;
}

.swiper-slide .s_cont .s-btn a {
  display: box;
  display: flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  box-align: center;
  box-pack: center;
  flex-align: center;
  flex-pack: center;
}

.slider1 .swiper-slider1-button-prev,
.slider1 .swiper-slider1-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  display: box;
  display: flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  /* 追加 */
  width: 60px;
  height: 60px;
  background-color: #ec6a00;
  cursor: pointer;
  transition: 0.3s;
  box-align: center;
  box-pack: center;
  flex-align: center;
  flex-pack: center;
}

.slider1 .swiper-slider1-button-next {
  right: 0;
}

.slider1 .swiper-slider1-button-prev:hover,
.slider1 .swiper-slider1-button-next:hover {
  background-color: #333;
}

.slider1 .swiper-slider1-button-prev::after,
.slider1 .swiper-slider1-button-next::after {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  border-width: 6px 7px 6px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  content: '';
  transition: 0.5s;
}

.slider1 .swiper-slider1-button-next::after {
  transform: rotate(180deg);
}

.slider1 .swiper-slider1-button-next:hover::after {
  transform: rotate(180deg);
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 120px !important;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  border-radius: inherit !important;
  background: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #ec6a00 !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px !important;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .swiper-slide.s2,
  .swiper-slide.s2,
  .swiper-slide.s3,
  .swiper-slide.s4,
  .swiper-slide.s5,
  .swiper-slide.s6,
  .swiper-slide.s7,
  .swiper-slide.s8,
  .swiper-slide.s9,
  .swiper-slide.s10 {
    background: none;
  }
  .swiper-slide.s2::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s2_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s3::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s3_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s4::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s4_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s5::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s5_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s6::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s6_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s7::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s7_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s8::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s8_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s9::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s9_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide.s10::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url(../img/s10_sp.jpg);
    background-position: 50% 0;
    background-size: cover;
    content: '';
  }
  .swiper-slide .s_cont .s-ttl {
    font-size: 26px;
  }
  .swiper-slide .s_cont .s-ttl::before {
    height: 11px;
  }
  .swiper-slide .s_cont .s-subttl {
    margin: 10px auto 0;
    line-height: 0;
  }
  .swiper-slide.s2 .s_cont .s-subttl {
    width: 212px;
    height: 8px;
  }
  .swiper-slide.s3 .s_cont .s-subttl {
    width: 143px;
    height: 8px;
  }
  .swiper-slide.s4 .s_cont .s-subttl {
    width: 113px;
    height: 8px;
  }
  .swiper-slide.s5 .s_cont .s-subttl {
    width: 75px;
    height: 8px;
  }
  .swiper-slide.s6 .s_cont .s-subttl {
    width: 102px;
    height: 8px;
  }
  .swiper-slide.s7 .s_cont .s-subttl {
    width: 110px;
    height: 8px;
  }
  .swiper-slide.s8 .s_cont .s-subttl {
    width: 53px;
    height: 8px;
  }
  .swiper-slide .s_cont .s-subttl img {
    width: 100%;
    height: auto;
  }
  .swiper-slide .s_cont .s-typo {
    margin-top: 20px;
    padding: 0 25px;
  }
  .swiper-slide .s_cont .s-txt {
    margin-top: 22px;
    font-size: 16px;
  }
  .swiper-slide .s_cont .s-txt .line {
    border-bottom-width: 3px;
  }
  .swiper-slide .s_cont .s-btn {
    margin: 45px auto 0;
    width: 165px;
    height: 35px;
    border: 2px solid #fff;
    transition: 0.3s;
  }
  .swiper-slide .s_cont .s-btn:hover {
    border: 2px solid #fff;
    background: none;
    transition: 0.3s;
  }
  .swiper-slide .s_cont .s-btn a {
    font-size: 13px;
  }
  .slider1 .swiper-slider1-button-prev,
  .slider1 .swiper-slider1-button-next {
    position: absolute;
    top: auto;
    bottom: 62px;
    z-index: 10;
    display: box;
    display: flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    /* 追加 */
    width: 36px;
    height: 36px;
    background-color: #ec6a00;
    cursor: pointer;
    transition: 0.3s;
    box-align: center;
    box-pack: center;
    flex-align: center;
    flex-pack: center;
  }
  .slider1 .swiper-slider1-button-next {
    right: 6.25%;
  }
  .slider1 .swiper-slider1-button-prev {
    right: 6.25%;
    margin-right: 36px;
    border-right: 1px solid #fff;
  }
  .slider1 .swiper-slider1-button-prev:hover,
  .slider1 .swiper-slider1-button-next:hover {
    background-color: #333;
  }
  .slider1 .swiper-slider1-button-prev::after,
  .slider1 .swiper-slider1-button-next::after {
    position: relative;
    display: block;
    width: 0;
    height: 0;
    border-width: 3px 4px 3px 0;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
    content: '';
    transition: 0.5s;
  }
  .slider1 .swiper-slider1-button-next::after {
    transform: rotate(180deg);
  }
  .slider1 .swiper-slider1-button-next:hover::after {
    transform: rotate(180deg);
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 70px !important;
    left: 0;
    width: 100%;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 6px !important;
    height: 6px !important;
    border-radius: inherit !important;
    background: #fff !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #ec6a00 !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 6px !important;
  }
  .swiper-slide {
    display: box;
    display: flexbox;
    display: flex;
    justify-content: center;
    box-pack: center;
    flex-pack: center;
  }
  .s2 .s_cont .s-txt-inner {
    display: none;
  }
  .s2 .s_cont .s-txt-inner .s-txt {
    display: none;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 75px !important;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
}

.item .item--01 .js-mv-image-item .is-active {
  opacity: 1 !important;
}

.js-mv-image {
  transform: inherit !important;
  transform: inherit !important;
  transform: inherit !important;
  transform: inherit !important;
  transform: inherit !important;
}

.s2 .s_cont .s-txt-inner {
  display: flex;
  justify-content: space-between;
}

.s2 .s_cont .s-txt-inner .s-txt {
  margin-top: 95px;
  width: 100%;
  font-size: 14px;
}

.swiper-slide {
  background-color: rgba(0, 0, 0, 0.6);
}

.sw .swiper-slide::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.17);
  content: '';
}

.sw .swiper-slide.s11::before {
  background: transparent;
}

.sw .swiper-slide .s_cont {
  position: relative;
  z-index: 2;
}

.swiper-slide.s9 .s_cont .s-ttl {
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s9 .s_cont .s-subttl {
    width: 45px;
    height: auto;
  }
}

.swiper-slide.s9 .s_cont .s-copy {
  margin: 40px auto 0;
  width: 50%;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s9 .s_cont .s-copy {
    margin: 30px auto 0;
    width: 90.5%;
    height: auto;
  }
}

.swiper-slide.s9 .s_cont .s-copy img {
  width: 100%;
  height: auto;
}

.swiper-slide.s9 .s_cont .s-txt {
  margin-top: 45px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s9 .s_cont .s-txt {
    margin-top: 45px;
  }
}

.swiper-slide.s9 .s_cont .s-btn {
  margin-top: 30px;
  border: 2px solid #fff;
  transition: 0.3s;
}

.swiper-slide.s9 .s_cont .s-btn :hover {
  background: #ec6a00;
  transition: 0.2s;
  transition: 0.2s;
  transition: 0.2s;
  transition: 0.2s;
  transition: 0.2s;
}

.swiper-slide.s9 .s_cont .s-btn a {
  background: #212121;
}

.swiper-slide.s9::before {
  background: rgba(0, 0, 0, 0.02);
  content: '';
}

.swiper-slide.s10 .s_cont .s-ttl {
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s10 .s_cont .s-subttl {
    width: 45px;
    height: auto;
  }
}

.swiper-slide.s10 .s_cont .s-copy {
  margin: 40px auto 0;
  width: 50%;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s10 .s_cont .s-copy {
    margin: 30px auto 0;
    width: 90.5%;
    height: auto;
  }
}

.swiper-slide.s10 .s_cont .s-copy img {
  width: 100%;
  height: auto;
}

.swiper-slide.s10 .s_cont .s-txt {
  margin-top: 45px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .swiper-slide.s10 .s_cont .s-txt {
    margin-top: 15px;
  }
}

.swiper-slide.s10::before {
  background: rgba(0, 0, 0, 0.02);
  content: '';
}

.swiper-slide.s11 .s_cont .s-txt {
  color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .sw .swiper-fade .swiper-slide {
    display: flex;
    align-items: center;
  }
}

.mv__images .item--01 {
  opacity: 1 !important;
}
